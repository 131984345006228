import { useTranslation } from "react-i18next";
import ContractsTable from "./ContractsTable";
import CreatePrincipalManagerDpaModal, * as createPrincipalManagerDpaModal from "./CreatePrincipalManagerDpaModal";
import { Contract } from "../../redux/contracts/contracts-types";
import { useState } from "react";
import { AppDispatch } from "../../interfaces/RootState";

/**
 * Props for {@link PersonalContractsView}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * ID of the user whose contracts are displayed and who signs them.
     */
    userId: number;

    /**
     * User's current personal DPA, if any.
     */
    dpa: Contract | null;

    /**
     * Whether some company the user is the principal manager of has an active subscription contract.
     */
    somePrincipallyManagedCompanyHasActiveSubscription: boolean;

    /**
     * Test IDs for component parts.
     */
    testIds?: TestIds;
};

/**
 * Test IDs for {@link PersonalContractsView}.
 */
export type TestIds = {
    dpaActionButton?: string;
    dpaModal?: createPrincipalManagerDpaModal.TestIds;
};

/**
 * {@link ContractsTable} specialized for the given user, together with modals for creating new contracts.
 */
export default function PersonalContractsView({
    dispatch,
    userId,
    dpa,
    somePrincipallyManagedCompanyHasActiveSubscription,
    testIds,
}: Props): JSX.Element {
    const { t } = useTranslation();

    const [isDpaModalOpen, setIsDpaModalOpen] = useState(false);

    const dpaSignDisabledReason = somePrincipallyManagedCompanyHasActiveSubscription
        ? null
        : t("contracts.tooltips.atLeastOneCompanySubscriptionMustBeSignedFirst");

    return (
        <>
            <CreatePrincipalManagerDpaModal
                dispatch={dispatch}
                isOpen={isDpaModalOpen}
                close={() => setIsDpaModalOpen(false)}
                userId={userId}
                initialData={{
                    managementCompanyName: "",
                    managementCompanyBusinessId: "",
                    managementCompanyStreetAddress: "",
                    managementCompanyZipCode: "",
                    managementCompanyTown: "",
                }}
                testIds={testIds?.dpaModal}
            />
            <ContractsTable
                dispatch={dispatch}
                rows={[
                    {
                        key: 1,
                        type: t("contracts.types.principalManagerDpa"),
                        contract: dpa,
                        signActionDisabledReason: dpaSignDisabledReason,
                        onSignClicked: () => setIsDpaModalOpen(true),
                        actionButtonTestId: testIds?.dpaActionButton,
                    },
                ]}
            />
        </>
    );
}
