import Centered from "./Centered";
import { MY_HYDROLINK_URL } from "../constants/urls";
import { useTranslation } from "react-i18next";
import { isSwedishVersion } from "../utils/location";

const getServiceName = () => {
    return isSwedishVersion() ? "My Hydrolink" : "Oma Hydrolink";
};

/**
 * Renders a centered element with text and a link to My Hydrolink.
 * The link is to OmaHydrolink with Hydrolink FI instance and to MyHydrolink SE with Hydrolink SE.
 *
 * @constructor
 */
const MyHydrolinkLink = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <Centered>
            <b>{t("login.myHydrolinkLink.title")}</b>
            <br />
            <span style={{ display: "inline" }}>
                {t("login.myHydrolinkLink.text", { service: getServiceName() })}{" "}
                <a href={MY_HYDROLINK_URL}>{t("login.myHydrolinkLink.linkText")}</a>
            </span>
        </Centered>
    );
};

export default MyHydrolinkLink;
