import { all, put, takeEvery } from "redux-saga/effects";
import * as api from "../../api";
import { ApiContract, ApiUserContracts } from "../../api/api-types";
import {
    CreateCompanyDPARequestAction,
    CreateCompanyDPARequestFailureAction,
    CreateCompanyDPARequestSuccessAction,
    CreateCompanySubscriptionRequestAction,
    CreateCompanySubscriptionRequestFailureAction,
    CreateCompanySubscriptionRequestSuccessAction,
    CreatePrincipalManagerDPARequestAction,
    CreatePrincipalManagerDPARequestFailureAction,
    CreatePrincipalManagerDPARequestSuccessAction,
    FetchUserContractsAction,
    FetchUserContractsFailureAction,
    FetchUserContractsSuccessAction,
    SendInviteeSignRequestAction,
    SendInviteeSignRequestFailureAction,
    SendInviteeSignRequestSuccessAction,
} from "./contracts-actions";
import { convertApiContract, convertApiUserContracts } from "../../api/api-conversions";
import { ErrorOccurredAction } from "../error/error-actions";
import {
    CreateCompanyDpaRequestPayload,
    CreateCompanySubscriptionRequestPayload,
    CreatePrincipalManagerDpaRequestPayload,
    FetchUserContractsRequestPayload,
    SendInviteeSignRequestPayload,
} from "./contracts-types";
import { AddMessageAction } from "../messages/messages-actions";
import { MessageTypes } from "../messages/messages-types";

export function* fetchUserContracts(action: { payload: FetchUserContractsRequestPayload }) {
    const userId = action.payload.userId;
    yield api.get(
        `/users/${userId}/contracts`,
        (resp: ApiUserContracts) =>
            put(
                FetchUserContractsSuccessAction({
                    userId,
                    contracts: convertApiUserContracts(resp),
                    background: action.payload.background ?? false,
                }),
            ),
        (err) =>
            all([
                put(FetchUserContractsFailureAction({ background: action.payload.background ?? false })),
                put(ErrorOccurredAction(err)),
            ]),
    );
}

export function* sendInviteeSignRequest(action: { payload: SendInviteeSignRequestPayload }) {
    const contractId: number = action.payload.contractId;
    yield api.post(
        `/contracts/${contractId}/send_invitee_sign_request`,
        {},
        () =>
            all([
                put(SendInviteeSignRequestSuccessAction({ contractId: contractId })),
                put(
                    AddMessageAction({
                        type: MessageTypes.success,
                        message: "contracts.signRequestSent",
                    }),
                ),
            ]),
        (err) => all([put(SendInviteeSignRequestFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* createPrincipalManagerDPAContract(action: { payload: CreatePrincipalManagerDpaRequestPayload }) {
    yield api.post(
        `/users/${action.payload.userId}/contracts/dpa`,
        {
            managementCompanyName: action.payload.managementCompanyName,
            managementCompanyBusinessId: action.payload.managementCompanyBusinessId,
            managementCompanyStreetAddress: action.payload.managementCompanyStreetAddress,
            managementCompanyZipCode: action.payload.managementCompanyZipCode,
            managementCompanyTown: action.payload.managementCompanyTown,
        },
        (resp: { created: ApiContract }) =>
            all([
                put(
                    CreatePrincipalManagerDPARequestSuccessAction({
                        userId: action.payload.userId,
                        created: convertApiContract(resp.created),
                    }),
                ),
                put(FetchUserContractsAction({ userId: action.payload.userId })),
            ]),
        (err) => all([put(CreatePrincipalManagerDPARequestFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* createCompanySubscriptionContract(action: { payload: CreateCompanySubscriptionRequestPayload }) {
    yield api.post(
        `/companies/${action.payload.companyId}/contracts/subscription`,
        {
            housingCompanyBusinessId: action.payload.housingCompanyBusinessId,
            housingCompanyStreetAddress: action.payload.housingCompanyStreetAddress,
            housingCompanyZipCode: action.payload.housingCompanyZipCode,
            housingCompanyTown: action.payload.housingCompanyTown,
            managementCompanyName: action.payload.managementCompanyName,
            managementCompanyBusinessId: action.payload.managementCompanyBusinessId,
            managementCompanyStreetAddress: action.payload.managementCompanyStreetAddress,
            managementCompanyZipCode: action.payload.managementCompanyZipCode,
            managementCompanyTown: action.payload.managementCompanyTown,
            managerPhone: action.payload.managerPhone,
        },
        (resp: { created: ApiContract }) =>
            all([
                put(
                    CreateCompanySubscriptionRequestSuccessAction({
                        companyId: action.payload.companyId,
                        created: convertApiContract(resp.created),
                    }),
                ),
                put(FetchUserContractsAction({ userId: action.payload.userId })),
            ]),
        (err) => all([put(CreateCompanySubscriptionRequestFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* createCompanyDPAContract(action: { payload: CreateCompanyDpaRequestPayload }) {
    yield api.post(
        `/companies/${action.payload.companyId}/contracts/dpa`,
        {
            businessId: action.payload.businessId,
            streetAddress: action.payload.streetAddress,
            zipCode: action.payload.zipCode,
            town: action.payload.town,
        },
        (resp: { created: ApiContract }) =>
            all([
                put(
                    CreateCompanyDPARequestSuccessAction({
                        companyId: action.payload.companyId,
                        created: convertApiContract(resp.created),
                    }),
                ),
                put(FetchUserContractsAction({ userId: action.payload.userId })),
            ]),
        (err) => all([put(CreateCompanyDPARequestFailureAction()), put(ErrorOccurredAction(err))]),
    );
}

export function* contractsSaga() {
    yield takeEvery(FetchUserContractsAction, fetchUserContracts);
    yield takeEvery(SendInviteeSignRequestAction, sendInviteeSignRequest);
    yield takeEvery(CreatePrincipalManagerDPARequestAction, createPrincipalManagerDPAContract);
    yield takeEvery(CreateCompanySubscriptionRequestAction, createCompanySubscriptionContract);
    yield takeEvery(CreateCompanyDPARequestAction, createCompanyDPAContract);
}
