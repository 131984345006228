const _API_URL = process.env.REACT_APP_API_URL;
const _ASSETS_HOST_URL = process.env.REACT_APP_ASSETS_HOST;
const _MY_HYDROLINK_URL = process.env.REACT_APP_MY_HYDROLINK_HOST;

function getApiURL() {
    return _API_URL;
}

function getAssetsHostURL() {
    return _ASSETS_HOST_URL;
}

function getMyHydrolinkURL() {
    return _MY_HYDROLINK_URL;
}

export const API_URL = getApiURL();

export const ASSETS_HOST = getAssetsHostURL();

export const MY_HYDROLINK_URL = getMyHydrolinkURL();
