import { Alert, AlertTitle } from "@mui/material";
import Centered from "../../components/Centered";
import { spacings } from "../../theme";
import { useTranslation } from "react-i18next";

/**
 * Information box about contracts; why they exist and how to sign them.
 */
export default function ContractInfoBox(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Centered style={{ marginBottom: spacings.standardSpacing }}>
            <Alert severity="info" style={{ width: "640px" }}>
                <AlertTitle>
                    <strong>{t("contracts.contractInfoBox.title")}</strong>
                </AlertTitle>
                <p>{t("contracts.contractInfoBox.info1")}</p>
                <p>{t("contracts.contractInfoBox.info2")}</p>
                <h4>{t("contracts.contractInfoBox.steps")}</h4>
                <ul>
                    <li>{t("contracts.contractInfoBox.step1")}</li>
                    <li>{t("contracts.contractInfoBox.step2")}</li>
                    <li>{t("contracts.contractInfoBox.step3")}</li>
                    <li>{t("contracts.contractInfoBox.step4")}</li>
                </ul>
            </Alert>
        </Centered>
    );
}
