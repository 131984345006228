import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { alignments } from "../../theme";
import { AppDispatch } from "../../interfaces/RootState";
import { ErrorOccurredAction } from "../../redux/error/error-actions";
import { CreatePrincipalManagerDPARequestAction } from "../../redux/contracts/contracts-actions";

/**
 * Props for {@link CreatePrincipalManagerDpaModal}.
 */
export type Props = {
    /**
     * Redux dispatch function.
     */
    dispatch: AppDispatch;

    /**
     * Whether the modal is open.
     */
    isOpen: boolean;

    /**
     * Function to close the modal (set `isOpen` to `false`).
     */
    close: () => void;

    /**
     * User ID of the manager for whom to create the contract.
     */
    userId: number;

    /**
     * Initial data for the form.
     */
    initialData: FormData;

    /**
     * Test IDs for the modal elements.
     */
    testIds?: TestIds;
};

/**
 * Form data for a principal manager DPA.
 */
export type FormData = {
    /**
     * Name of the management company (isännöitsijätoimisto).
     */
    managementCompanyName: string;
    /**
     * Business ID (Y-tunnus) of the management company.
     */
    managementCompanyBusinessId: string;

    /**
     * Street address of the management company.
     */
    managementCompanyStreetAddress: string;

    /**
     * ZIP code of the management company.
     */
    managementCompanyZipCode: string;

    /**
     * Town of the management company.
     */
    managementCompanyTown: string;
};

/**
 * Test IDs for {@link CreatePrincipalManagerDpaModal}.
 */
export type TestIds = {
    closeButton?: string;
    saveButton?: string;
    managementCompanyNameInput?: string;
    managementCompanyBusinessIdInput?: string;
    managementCompanyStreetAddressInput?: string;
    managementCompanyZipCodeInput?: string;
    managementCompanyTownInput?: string;
};

/**
 * Modal for entering data for a principal manager DPA.
 */
export default function CreatePrincipalManagerDpaModal({
    dispatch,
    isOpen,
    close,
    userId,
    initialData,
    testIds,
}: Readonly<Props>) {
    const { t } = useTranslation();

    const [data, setData] = useState(initialData);

    const resetAndClose = () => {
        setData(initialData);
        close();
    };

    const onChange = (field: keyof FormData) => (e: React.ChangeEvent<HTMLInputElement>) =>
        setData((prev) => ({ ...prev, [field]: e.target.value }));

    const onSave = () => {
        const errorKey = validateFormData(data);
        if (errorKey !== null) {
            dispatch(ErrorOccurredAction(errorKey));
            return;
        }
        dispatch(CreatePrincipalManagerDPARequestAction({ userId, ...data }));
        resetAndClose();
    };

    const onClose = resetAndClose;

    const DialogActions = () => {
        return (
            <>
                <Button onClick={onClose} data-testid={testIds?.closeButton}>
                    {t("generic.cancel")}
                </Button>
                <Button variant="contained" onClick={onSave} data-testid={testIds?.saveButton}>
                    {t("generic.save")}
                </Button>
            </>
        );
    };

    return (
        <Modal
            title={t("contracts.forms.createPrincipalManagerDpa.title")}
            open={isOpen}
            dialogActions={<DialogActions />}
            closeModal={onClose}
        >
            <div style={{ ...alignments.twoElementGrid }}>
                <ModalInput
                    key="managementCompanyName"
                    title={`${t("contracts.forms.createPrincipalManagerDpa.fields.managementCompanyName")} *`}
                    value={data.managementCompanyName}
                    handleFormValue={onChange("managementCompanyName")}
                    dataTestId={testIds?.managementCompanyNameInput}
                />
                <ModalInput
                    key="managementCompanyBusinessId"
                    title={`${t("contracts.forms.createPrincipalManagerDpa.fields.managementCompanyBusinessId")} *`}
                    value={data.managementCompanyBusinessId}
                    handleFormValue={onChange("managementCompanyBusinessId")}
                    dataTestId={testIds?.managementCompanyBusinessIdInput}
                />
                <ModalInput
                    key="managementCompanyStreetAddress"
                    title={`${t("contracts.forms.createPrincipalManagerDpa.fields.managementCompanyStreetAddress")} *`}
                    value={data.managementCompanyStreetAddress}
                    handleFormValue={onChange("managementCompanyStreetAddress")}
                    dataTestId={testIds?.managementCompanyStreetAddressInput}
                />
                <ModalInput
                    key="managementCompanyZipCode"
                    title={`${t("contracts.forms.createPrincipalManagerDpa.fields.managementCompanyZipCode")} *`}
                    value={data.managementCompanyZipCode}
                    handleFormValue={onChange("managementCompanyZipCode")}
                    dataTestId={testIds?.managementCompanyZipCodeInput}
                />
                <ModalInput
                    key="managementCompanyTown"
                    title={`${t("contracts.forms.createPrincipalManagerDpa.fields.managementCompanyTown")} *`}
                    value={data.managementCompanyTown}
                    handleFormValue={onChange("managementCompanyTown")}
                    dataTestId={testIds?.managementCompanyTownInput}
                />
            </div>
        </Modal>
    );
}

/**
 * Validate the form data.
 *
 * @param data Form data to validate.
 * @returns Error message translation key if the data is invalid, otherwise `null`.
 */
function validateFormData(data: FormData): string | null {
    const key = (suffix: string) => `contracts.forms.createPrincipalManagerDpa.errors.${suffix}`;

    const empty = /^\s*$/;

    if (empty.test(data.managementCompanyName)) {
        return key("managementCompanyNameMissing");
    }
    if (empty.test(data.managementCompanyBusinessId)) {
        return key("managementCompanyBusinessIdMissing");
    }
    if (empty.test(data.managementCompanyStreetAddress)) {
        return key("managementCompanyStreetAddressMissing");
    }
    if (empty.test(data.managementCompanyZipCode)) {
        return key("managementCompanyZipCodeMissing");
    }
    if (empty.test(data.managementCompanyTown)) {
        return key("managementCompanyTownMissing");
    }

    return null;
}
